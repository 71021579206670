#root {
  /* max-width: 1280px; */
  margin: 0 auto;
  text-align: center;
}
* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.darkTheme {
  color: white !important;
  background-color: #181818;
}
