.input-container {
  text-align: left;
  padding: 2.5rem 2rem 3rem 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 0 10px 2px #00000025;
}

#submit-btn,
#submit-btn-loading {
  border-radius: 8px;
  border: 2px solid transparent;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: 0.5s ease;
  background-color: rgb(78, 70, 228);
  color: white;
}

#submit-btn:hover,
#submit-btn:focus {
  outline: none;
  color: black;
  border-color: rgba(78, 70, 228);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(78 70 228 / 30%);
}

.quizSubmitted {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.input-field {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 13px;
  outline: none;
  background-color: transparent;
  transition: all 0.3s ease;
  border-radius: 0 5px 5px 0;
  font-weight: 500;
}

.input-field:not(input[type="tel"]) {
  border: 1px solid #d7dce2;
  border-radius: 5px;
}

.input-field:is(input[type="tel"]) {
  border-top: 1px solid #d7dce2;
  border-right: 1px solid #d7dce2;
  border-bottom: 1px solid #d7dce2;
  border-radius: 0 5px 5px 0;
}

.input-container select {
  font-size: 13px;
  padding: 0 10px;
  color: #6e6e6e;
}

.input-container select:focus {
  color: black;
}

.input-label {
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 60px;
  font-size: 16px;
  color: rgba(204, 204, 204, 0);
  pointer-events: none;
  transition: all 0.3s ease;
  height: 12px;
}

.input-field:not(input[type="tel"]) + .input-label {
  left: 10px;
}

.input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #4e46e4;
  transition: all 0.3s ease;
}

.input-field:focus::placeholder {
  opacity: 0;
}

.input-field:focus + .input-label {
  top: -11px;
  font-size: 13px;
  color: #4e46e4;
  background: white;
}

.input-field:not(input[type="tel"]):focus + .input-label {
  left: 10px;
}

.input-field:focus + .input-label + .input-highlight {
  width: 100%;
}
.leftDiv {
  background-color: #dad2ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='300' height='300' viewBox='0 0 120 120'%3E%3Cpolygon fill='%238A7BEE' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/%3E%3C/svg%3E");
}
.timer > div > div > div {
  font-size: 13px !important;
  font-weight: 700 !important;
}
@media screen and (max-width: 786px) {
  .input-container {
    padding: 1rem;
    box-shadow: none;
    gap: 15px;
  }
}
